<template>
  <div class="card_content">
  <h3>Где нужен ремонт?</h3>
  <ul class="types__list">
    <li class="types__item" v-for="type in types" v-bind:key="type">
      <input class="types__radio" type="radio" name="type" :id="type"         :value="type" 
      @change="createObject(type)"/>
      <label class="types__card" :for="type">
        <img class="types__image" :src="require(`@/assets/images/${type}.jpg`)" :alt="type">
      </label>
      <h4 class="types__title">{{ type }}</h4>
    </li>
  </ul>
</div>
</template>

<script>

export default {
  name: 'ObjectType',
  emits: ['create-object'],
  props: ['ownerId', 'modelValue'],
  data () {
    return {
      objectId: null,
      object: null,
      types: ['Квартира', 'Дом', 'Офис', 'Санузел'],
      choosedType: 'Квартира',
    }
  },
  methods: {
    createObject(description) {
      this.$emit('create-object', description)
    }
  },
}
</script>


<style lang="sass" scoped>
.types
  &__list
    display: grid
    grid-template-columns: repeat(2, 1fr)
    align-items: center
    justify-content: center
    gap: 1rem 3rem
  &__card
    border-radius: 1.6rem
    cursor: pointer
    transition: .3s ease-in-out
    position: relative
    overflow: hidden
    margin: 0
    height: 26rem
    &:hover
      & + .types__title
        border-color: $black
  &__image
    filter: grayscale(100%)
    object-fit: cover
    position: relative
    transition: .3s ease-in-out
    height: 100%
    width: 100%
  &__radio
    display: none
    &:checked,
    &:hover
      & + .types__card h4
        background-color: #fff4d4
    & + label
      border-radius: 1.6rem
      cursor: pointer
      padding-left: 0
      width: 100%
      &::before
        display: none
      
  &__title
    display: inline-flex
    border-bottom: 1px solid transparent
    color: $black
    font-size: 1.4rem
    font-weight: 600
    text-transform: uppercase
    padding: 1rem 0 0.5rem
    margin: 0
    transition: .3s ease-in-out
    z-index: 2

.btn
  margin-left: auto

</style>
