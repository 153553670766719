<template>
  <form class="form">
    <div class="form__row">
      <h3>Поиск доступных работ</h3>
      <p>100+ доступных работ</p>
    </div>
    <div class="form__row">
      <input type="text" name="" id="">
      <button class="btn"><i class="fa-solid fa-location-dot"></i>Магнитогорск</button>
      <button class="btn btn--accent">Поиск работ</button>
    </div>
    <div class="form__row">
      <input type="checkbox" id="checkbox-btn-1" name="checkbox-btns">
      <label for="checkbox-btn-1" class="btn">Демонтажные работы</label>
    
      <input type="checkbox" id="checkbox-btn-2" name="checkbox-btns">
      <label for="checkbox-btn-2" class="btn">Монтажные работы</label>
    
      <input type="checkbox" id="checkbox-btn-3" name="checkbox-btns">
      <label for="checkbox-btn-3" class="btn">Отделочные работы</label>

      <input type="checkbox" id="checkbox-btn-4" name="checkbox-btns">
      <label for="checkbox-btn-4" class="btn">Строительные работы</label>
    </div>
  </form>
</template>

<script>
export default {
name: 'ObjectsList',
components: {

},
data () {
return {

}
},
methods: {

},
}
</script>

<style lang="sass" scoped>
.form
  grid-column: 1 / 3
  background: $accent-light
  border-radius: $border-radius
  padding: 3rem 2.6rem 1.6rem
  margin-bottom: 2.6rem
  & h3
    font-size: 3rem
    font-weight: 700
    margin-bottom: 2.6rem
  &__row
    display: flex
    gap: 1.2rem
    & button
      border-radius: 5rem
      min-width: 17rem
      &:first-of-type
        background: $white
        border: none
        & i
          color: $accent
    &:first-of-type
      justify-content: space-between
      & p
        opacity: .4
    &:last-of-type
      margin-top: 2rem
  & input
    background: $white
    border: none
    border-radius: 5rem
    flex: 1

input[type=checkbox]
  display: none

input[type=checkbox] + label.btn
  background: $white
  border: none
  border-radius: 5rem
  cursor: pointer
  font-size: 1.3rem
  font-weight: 700
  transition: all 0.3s

input[type=checkbox] + label.btn:hover


input[type=checkbox]:checked + label.btn
  background: $accent

</style>