<template>
  <ul class="dropdown">
    <li>
      <router-link to="/account" class="dropdown_link"> <i class="fa-solid fa-gear"></i>
        Настройки</router-link>
    </li>
    <li>
      <a class="dropdown_link" href="#" @click.prevent="logout">
        <i class="fa-solid fa-arrow-right-from-bracket"></i>
        Выйти
      </a>
    </li>
  </ul>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DropdownMenu',
  methods: {
    ...mapActions([
      'REMOVE_OBJECT',
    ]),
    logout() {
      this.$store.dispatch('auth/logout')
      this.REMOVE_OBJECT()
      this.$router.push('/')
    }
  }
}
</script>

<style lang="sass" scoped>
.dropdown
  border: 1px solid #E8E8E8
  border-radius: 1.6rem
  background-color: $white
  display: flex
  flex-direction: column
  position: absolute
  opacity: 0
  transition: .25s ease-in-out
  top: 4.6rem
  right: 0
  padding: 0.8rem 1.6rem
  min-width: 20rem
  z-index: 1
  &.active
    opacity: 1
  & li 
    display: flex
    align-items: center
    height: 3.6rem
  &_link
    color: $col-black
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 0.8rem
    font-size: 1.4rem
    margin: 0
    & i
      margin-top: 0.1rem
      width: 1.6rem
  & .close
    color: $gray
    position: absolute
    right: 1.6rem
    top: 1.6rem
</style>