<template>
  <div class="modal">
    <Form class="card form" @submit="addWork" :validation-schema="schema" v-slot="{ errors }">
      <button class="close" @click.prevent="closeModal"><i class="fa-solid fa-plus"></i></button>
      <div class="icon"><i class="fa-solid fa-trowel"></i></div>
      <h2>Добавить работу</h2>
      <Field name="name" type="text" :class="{'invalid' : errors.name}" placeholder="Наименование" />
      <div class="form__group">
        <Field name="measurementValue" type="text" :class="{'invalid' : errors.measurementValue}" placeholder="Единица измерения" />
        <Field name="cost" type="number" :class="{'invalid' : errors.cost}" placeholder="Цена, р." min="0" max="999999" />
      </div>
      <div class="form__buttons">
        <button class="btn btn--gray" type="button" @click.prevent="closeModal">Отмена</button>
        <button class="btn btn--black" type="submit">Сохранить</button>
      </div>
    </Form>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service"
import { Form, Field } from "vee-validate"
import * as yup from "yup"

export default {
  props: ['id'],
  emits: ['close-modal'],
  components: {
    Form,
    Field,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      measurementValue: yup.string().required(),
      cost: yup.string().required(),
    })
    return {
      schema,
    }
  },
  methods: {
    check(info) {
      console.log(info);
    },
    addWork(info) {
      AdminService.addWorkToGroup(info.name, this.id )
        .then(response => {
          const workTypeId = response.data.id
          return AdminService.addInfoToWork(workTypeId, info.measurementValue, info.cost)
        })
        .then(response => {
          console.log('Info added to work successfully:', response.data)
          this.closeModal()
        })
        .catch(error => {
          console.error('Error creating work:', error)
        })
    },
    closeModal() {
      this.$emit('close-modal')
    }
  },
}
</script>

<style lang="sass" scoped>
.modal
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center

.card
  background-color: #fff
  padding: 8rem
  width: 100%
  max-width: 57.6rem

.form
  position: relative
  &__group
    display: flex
    gap: 1.6rem
  &__buttons
    display: flex
    align-items: center
    justify-content: space-between
    gap: 1.6rem
    & .btn
      height: 5.6rem
      width: 100%
  & input
    margin-bottom: 1.6rem

.icon
  background: #F1F2F3
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto 2.8rem
  height: 8rem
  width: 8rem
  & i
    font-size: 3.2rem

.close
  position: absolute
  top: 3rem
  right: 3rem
  transform: rotate(45deg)
  color: #1C1C1C

h2
  text-align: center
  margin-bottom: 2.8rem
</style>