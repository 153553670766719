<template>
  <div class="type">
    <button>
      <i class="fa-regular fa-face-smile"></i>
    </button>
    <input type="text" placeholder="Напишите сообщение" v-model="reply">
    <button>
      <i class="fa-solid fa-paperclip"></i>
    </button>
    <button class="type_send-btn" @click="sendReply">
      <i class="fa-solid fa-paper-plane"></i>
    </button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      reply: null
    }
  },
  methods: {
    sendReply() {
      this.$emit('sendReply', this.reply)
    }
  }
}
</script>

<style lang="sass" scoped>
.type
  grid-area: 4 / 2 / 5 / 3
  background: $white
  display: flex
  align-items: center
  border-top: 1px solid $border
  padding: 2rem 3.6rem
  height: 9rem
  & i
    font-size: 2.2rem
    opacity: .3
    margin-right: 1.6rem
  & input
    border: none
    padding: 0
    height: 100%
  &_send-btn i
    opacity: .9
    margin-left: 0.6rem
</style>