<template>
  <div class="account">
    <div class="card">
      <!-- <img class="logo" :src="require(`@/assets/images/logo.png`)" alt="logo" draggable="false"> -->
      <LoginForm v-if="form === 'login'" @register="form = 'register'"/>
      <RegisterForm v-if="form === 'register'" @login="form = 'login'"/>
    </div>
  </div>

</template>

<script>
import LoginForm from '@/components/Auth/LoginForm.vue'
import RegisterForm from '@/components/Auth/RegisterForm.vue'

export default {
  components: {
    LoginForm,
    RegisterForm
  },
  emits: ['close-modal'],
  data () {
    return {
      form: 'login'
    }
  },
  mounted() {
    if (this.$store.state.auth.status.loggedIn === true) {
      const role = this.$store.state.auth.role
      if (role === 'ROLE_ADMIN') {
        this.$router.push('/admin')
      } else {
        this.$router.push('/profile')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.account
  background: $background
  display: flex
  align-items: center
  padding: 10rem 0
  min-height: 100vh
.logo
  margin: 0 auto 2.8rem
  width: 6.3rem
.card
  background: $white
  border-radius: 2.4rem
  display: flex
  flex-direction: column
  align-items: center
  padding: 10.1rem 14.8rem
  margin: 0 auto 0
  height: 65.6rem
  width: 100%
  max-width: 68rem
  z-index: 111
</style>