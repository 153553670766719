<template>
  <div class="wrapper">
    <div class="container">
      <div class="grid">
        <AccountImage />
        <AccountPassword />
        <AccountInfo />
      </div>
    </div>
  </div>
</template>

<script>
import AccountImage from '@/components/Account/AccountImage.vue'
import AccountPassword from '@/components/Account/AccountPassword.vue'
import AccountInfo from '@/components/Account/AccountInfo.vue'

export default {
  name: 'Account',
  components: {
    AccountImage,
    AccountPassword,
    AccountInfo
  },
  methods: {

  }
}
</script>

<style lang="sass" scoped>
.grid
  @media (min-width: $small)
    display: grid
    column-gap: 3.4rem
  @media (min-width: $medium)
    grid-template-columns: 35% auto

.wrapper
  background: $white
  display: flex
  margin-top: 8.6rem
  min-height: calc(100vh - 9rem)

.container
  padding: 3.6rem
  
</style>