import api from "./api"
import token from "./token"
import TokenService from "./token.service"

const setup = (store) => {
  // Request interceptor to set the Authorization header
  api.interceptors.request.use(
    (config) => {
      const accessToken = TokenService.getLocalAccessToken()
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Response interceptor to handle token refreshing
  api.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      const originalConfig = error.config
      if (originalConfig.url !== '/auth/login' && error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          try {
            const rs = await token.post('/auth/token', {
              refreshToken: TokenService.getLocalRefreshToken(), 
            })
            const accessToken = rs.data.accessToken
            store.dispatch('auth/token', accessToken)
            TokenService.updateLocalAccessToken(accessToken)
            return api(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }
      return Promise.reject(error)
    }
  )
}

export default setup