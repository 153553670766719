<template>
  <div class="card card--light">
    <div class="card__header">
      <div class="card__title">
        <h4>1. Название комнаты</h4>
      </div>
    </div>
    <Form class="form">
      <div class="form__group">
        <Field type="text" name="name" autocomplete="off" @input="changeName"/>
        <ErrorMessage name="name" class="error-feedback" />
      </div>
    </Form>

  </div>
  <div class="card card--light">
    <div class="card__header">
      <div class="card__title">
        <h4>2. Размер комнаты</h4>
      </div>
    </div>
    <h5>Укажите параметры комнаты в метрах</h5>
    <Form class="form" @input="changeSize" :validation-schema="schema">
      <div class="form__row">
        <div class="form__group">
          <label for="length">Длина</label>
          <Field type="number" min="0" max="999" name="length" v-model="size.length" placeholder="0" />
          <ErrorMessage name="length" class="error-feedback" />
        </div>
        <div class="form__group">
          <label for="width">Ширина</label>
          <Field type="number" min="0" max="999" name="width" v-model="size.width" placeholder="0" />
          <ErrorMessage name="width" class="error-feedback" />
        </div>
        <div class="form__group">
          <label for="height">Высота</label>
          <Field type="number" min="0" max="999" name="height" v-model="size.height" placeholder="0" />
          <ErrorMessage name="height" class="error-feedback" />
        </div>
      </div>
    </Form>
  </div>
  
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate"
import * as yup from "yup"

export default {
  name: 'ObjectRoomSize',
  emits: ['change-name', 'change-size'],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data () {
    const schema = yup.object().shape({
      length: yup.string().required('Укажите длину комнаты'),
      width: yup.string().required('Укажите ширину комнаты'),
      height: yup.string().required('Укажите высоту комнаты'),
    })
    return {
      schema,
      message: '',
      size: {
        type: 'SIZE',
        height: null,
        length: null,
        width: null,
      }
    }
  },
  methods: {
    changeName(e) {
      this.$emit('change-name', e.target.value)
    },
    changeSize() {
      if (this.size.length && this.size.width && this.size.height) {
        this.$emit('change-size', this.size)
      }
    },
  }
}
</script>

<style lang="sass" scoped>
h4
  margin: 0
h5
  font-weight: 600
  margin: 0 0 1.6rem
input
  border-radius: 0.8rem
  font-size: 1.4rem
  padding: 1rem 1.2rem
  height: 4.2rem

.card
  &__check
    background: $accent
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    margin-right: 1.8rem
    height: 2.2rem
    width: 2.2rem
    & i
      font-size: 1rem
      margin-top: 0.2rem

  &__header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 2rem
  &__title
    display: flex
    align-items: center

.form
  &__group
    width: 100%
  &__row
    display: flex
    gap: 1.6rem
    width: 100%

.btn 
  margin-left: auto
  margin-top: 2rem
</style>