import { createRouter, createWebHistory } from 'vue-router'
// import store from '../store'

import Home from '../views/Home.vue'
import Create from '../views/Create.vue'
import Account from '../views/Account.vue'
// import ProfilePage from '../views/ProfilePage.vue'
import Profile from '../views/Profile.vue'
import Messages from '../views/Messages.vue'
import HomeHeader from '../components/Layout/HomeHeader.vue'
import ProfileHeader from '../components/Layout/ProfileHeader.vue'
import AppFooter from '../components/Layout/AppFooter.vue'
import SideMenu from '../components/Layout/SideMenu.vue'
import AccessDenied from '../components/Layout/AccessDenied.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

import Admin from '../views/Admin.vue'
import WorkGroups from '@/components/Admin/WorkGroups.vue'

import Specialist from '../views/SpecialistProfile.vue'
import ObjectView from '../components/Specialist/ObjectView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      default: Home,
      header: HomeHeader,
      footer: AppFooter
    }
  },
  {
    path: '/create',
    name: 'create',
    components: {
      default: Create,
      header: HomeHeader,
      footer: AppFooter
    }
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: Login,
      header: HomeHeader,
      footer: AppFooter
    }
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      default: Register,
      header: HomeHeader,
      footer: AppFooter
    }
  },
  {
    path: '/profile',
    name: 'profile',
    components: {
      profile: Profile,
      header: ProfileHeader,
      //sidebar: SideMenu,
    },
    meta: {
      requiresLogin: true,
      requiresRole: 'ROLE_USER'
    }
  },
  {
    path: '/messages',
    name: 'messages',
    components: {
      profile: Messages,
      header: ProfileHeader,
      //sidebar: SideMenu,
    },
    meta: { requiresLogin: true }
  },
  {
    path: '/account',
    name: 'account',
    components: {
      profile: Account,
      header: ProfileHeader,
      //sidebar: SideMenu,
    },
    meta: { requiresLogin: true }
  },
  {
    path: '/spec',
    name: 'spec',
    components: {
      profile: Specialist,
      header: ProfileHeader,
      //sidebar: SideMenu,
    },
    meta: { 
      requiresLogin: true,
      requiresRole: 'ROLE_SPECIALIST'
    }
  },
  {
    path: '/spec/object',
    name: 'object',
    components: {
      profile: ObjectView,
      header: ProfileHeader,
      //sidebar: SideMenu,
    },
    meta: { 
      requiresLogin: true,
      requiresRole: 'ROLE_SPECIALIST'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    components: {
      profile: Admin,
      header: ProfileHeader,
      sidebar: SideMenu,
    },
    meta: { 
      requiresLogin: true,
      requiresRole: 'ROLE_ADMIN'
    }
  },
  {
    path: '/groups',
    name: 'WorkGroups',
    components: {
      profile: WorkGroups,
      header: ProfileHeader,
    },
    meta: { 
      requiresLogin: true,
      requiresRole: 'ROLE_ADMIN'
    },
    props: true,
  },
  {
    path: '/access-denied',
    component: AccessDenied
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const requiresLogin = to.meta.requiresLogin
//   const requiresRole = to.meta.requiresRole
//   const userStatus = store.state.auth.status.loggedIn
//   const userRole = store.state.auth.role

//   if (requiresLogin && userStatus !== requiresLogin) {
//     next('/access-denied')
//   } else if (requiresRole && userRole !== requiresRole) {
//     next('/access-denied')
//   } else {
//     next()
//   }
// })

export default router
