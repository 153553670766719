import AdminService from '../services/admin.service'

export default ({
  state: {
    groups_admin: []
  },
  mutations: {
    SET_GROUPS(state, groups) {
      state.groups_admin = groups
    },
  },
  actions: {
    async GET_GROUPS_ADMIN({ commit }) {
      try {
        const response = await AdminService.getGroups()
        commit('SET_GROUPS', response.data)
        return response.data        
      } catch (error) {
        console.error('Error getting work groups: ', error)
        return error
      }
    },
    async GET_GROUP_WORKS(workTypeGroupId) {
      try {
        const response = await AdminService.getGroupWorks(workTypeGroupId)
        return response.data        
      } catch (error) {
        console.error('Error getting works in group: ', error)
        return error
      }
    },
  },
  getters: {
    GROUPS_ADMIN(state) {
      return state.groups_admin
    },
  },
})