<template>
  <div class="wrapper">
    <h3>{{ choosedRoom.name }}</h3>
    <ObjectRoomSize 
      @change-name="changeName"
      @change-size="changeSize"
    />

    <div class="card card--light">
      <div class="works">
        <h4>Необходимые работы</h4>
        {{ groupId }}
        <ul class="works_list">
          <li v-for="group in groups" :key="group.id" class="works_item" @click="getGroupWorks(group.id)">
            {{ group.name }}
          </li>
        </ul>
  
        <ObjectWorks 
        v-if="groupId" 
        :roomId="choosedRoom.id" 
        :groupId="groupId"
        @go-back="goBack"
      />
      </div>

    </div>


    <div class="card_footer">
      <button class="btn btn--white" @click="this.$emit('set-component', 'ObjectRooms')">
        Назад
      </button>
      <button class="btn btn--black" @click="updateRooms">
        Сохранить
      </button>
    </div>
  </div>

</template>

<script>
import ObjectService from "@/services/object.service"
import ObjectRoomSize from '@/components/Create/ObjectRoomSize.vue'
import ObjectWorks from '@/components/Create/ObjectWorks.vue'

export default {
  props: ['choosedRoom'],
  emits: ['set-component', 'update-rooms'],
  components: {
    ObjectRoomSize,
    ObjectWorks,
  },
  data () {
    return {
      // openingsNumber: null,
      // openings: [],
      type: null,
      groups: null,
      works: null,
      choosedWorks: null,
      groupId: null,
      name: '',
      info: [],
    }
  },
  // computed: {
  //   roomArea() {
  //     return Math.round(this.room.description.lngth * this.room.description.width * 100) / 100
  //   },
  //   wallsArea() {
  //     return Math.round(((this.room.description.lngth * this.room.description.height) * 2 + 
  //                         (this.room.description.width * this.room.description.height) * 2) * 100) / 100
  //   },
  //   openingsArea() {
  //     let area = 0
  //     for (let i = 0; i < this.openings.length; i++) {
  //       area += this.openings[i].width * this.openings[i].height
  //     }
  //     return area
  //   },
  //   totalArea() {
  //     return this.roomArea * 2 + this.wallsArea - this.openingsArea
  //   },
  // },
  methods: {
    // addOpenings() {
    //   this.openings = []
    //   for (let i = 0; i < this.openingsNumber; i++) {
    //     this.openings.push({'width': null, 'height': null})
    //   }
    // },
    changeName(name) {
      this.name = name
    },
    changeSize(size) {
      this.info[0] = size
      console.log(this.info);
    },
    updateRooms() {
      // put request
      this.$emit('update-rooms', this.name, this.info )
    },
    goBack() {
      this.choosedWorks = null
      this.groupId = null
    },
    chooseGroup(id) {
      this.type = id
      for (let i = 0; i < this.groups.length; i++) {
        const group = this.groups[i]
        console.log(group);
      }
    },
    getGroupWorks(groupId) {
      this.groupId = groupId
      // this.choosedWorks = this.works.filter(work => work.workTypeGroupId === groupId)
    },
  },
  mounted() {
    ObjectService.getGroups().then(response => {
      this.groups = response.data
    })
  }
}


</script>

<style lang="sass" scoped>
.card
  &_footer
    display: flex
    justify-content: space-between

.grid
  display: grid
  grid-template-columns: 1fr 43%
  gap: 2rem
  margin-bottom: 3rem
.parameters
  &__buttons
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 1.6rem
  &__list
    display: flex
    gap: 2rem
  & li p
    font-size: 1.5rem
    letter-spacing: normal
    margin-bottom: 1.2rem
  &__body
    display: flex
    gap: 2rem
  &__area
    border: 1px solid $border
    border-radius: 1.6rem
    display: flex
    flex-direction: column
    padding: 3rem
    & li
      display: flex
      justify-content: space-between
      align-items: flex-end
      margin-bottom: 2rem
      &:last-of-type
        margin-top: auto
      &:last-of-type > b
        font-size: 2.6rem
        margin-top: auto
      

  &__input-group
    display: flex
    justify-content: space-between
    align-items: center
    gap: 1.6rem
    & li
      flex-direction: column
      align-items: flex-start
  &__list

    &:first-of-type
      & > li:last-of-type
          margin: 0
          & input
            margin: 0

      
  &__item
    display: flex
    flex-direction: column
    margin-bottom: 1.5rem
    width: 100%

  &__summary
    display: flex
    justify-content: flex-end
    padding: 4rem 0 0 0


.plan
  border: 1px solid $border
  border-radius: 1.6rem
  display: flex
  align-items: center
  justify-content: center
  padding: 3rem
  &__floor
    border: 4px solid $black
    transition: 0.1s ease-in-out
    display: flex
    align-items: center
    justify-content: center
    position: relative
    height: 18rem
    width: 25rem
  &__window
    background-color: $white
    border: 1px solid $black
    position: absolute
    top: -0.4rem
    left: 13rem
    height: 0.4rem
    width: 6rem
  &__door
    background-color: $white
    position: absolute
    top: 10rem
    left: -3rem
    transform: rotate(90deg)
    height: 1rem
    width: 5rem
    &::after
      content: ''
      background-color: $black
      position: absolute
      top: -0.8rem
      left: 0.2rem
      transform: rotate(20deg)
      height: 0.2rem
      width: 5rem
  &__area
    font-style: italic
    font-size: 1.8rem
    color: $gray
.works
  margin: 3.4rem 0 4rem
  &_list
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1.2rem
    margin-bottom: 1rem
    & li
      background-color: $background
      border-radius: 1.6rem
      font-weight: 500
      font-size: 1.5rem
      text-align: left
      text-transform: uppercase
      transition: 0.25s ease-in-out
      padding: 0 3rem
      position: relative
      letter-spacing: 0.2px
      display: flex
      align-items: center
      justify-content: space-between
      height: 11rem
      width: 100%
      &:hover
        cursor: pointer
        background: $accent
    
</style>