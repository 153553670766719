<template>
  <aside class="filters">
    <h4>
      Фильтры
    </h4>
  </aside>
</template>

<style lang="sass" scoped>
.filters
  grid-column: 1 / 2

</style>