<template>
  <div class="wrapper">
    <div class="container">
      <MessagesHeader />
      <SearchInput />
      <ConversationsSection @choose-conversation="chooseConversation"/>
      <ChatHeader :name="conversation.name"/>
      <ChatSection :conversation="conversation" :reply="reply"/>
      <MessageInput @send-reply="sendReply"/>
    </div>
  </div>
</template>

<script>
import MessagesHeader from '@/components/Messages/MessagesHeader.vue'
import SearchInput from '@/components/Messages/SearchInput.vue'
import ConversationsSection from '@/components/Messages/ConversationsSection.vue'
import ChatHeader from '@/components/Messages/ChatHeader.vue'
import ChatSection from '@/components/Messages/ChatSection.vue'
import MessageInput from '@/components/Messages/MessageInput.vue'


export default {
  name: 'Messages',
  components: {
    MessagesHeader,
    SearchInput,
    ConversationsSection,
    ChatHeader,
    ChatSection,
    MessageInput,
  },
  data() {
    return {
      conversation: {
        name: 'Александр Новиков',
        message: 'Lorem ipsum',
        time: '13:54',
        online: true
      },
      reply: null
    }
  },
  methods: {
    chooseConversation(conversation) {
      this.conversation = conversation
    },
    sendReply(reply) {
      this.reply = reply
      console.log(reply);
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  background: $white
  display: flex
  margin-top: 8.6rem
  min-height: calc(100vh - 9rem)
  @media (min-width: $medium)
    margin-left: 30rem
.container
  display: grid
  grid-template-columns: 30% 70%
  grid-template-rows: auto auto 1fr auto
  grid-column-gap: 0px
  grid-row-gap: 0px
  padding: 0
  position: relative
  max-width: none
  
</style>