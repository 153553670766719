<template>
  <section class="card">
    <h2>{{ description }}</h2>

    <p>Бюджет</p>
    <h3>300,000₽</h3>

    <p>Дата начала</p>
    <h3>29 Апр, 2024</h3>

    <p>Дата окончания</p>
    <h3>31 Авг, 2024</h3>

    <p>Комнаты</p>
    <ul class="card__tags">
      <li class="card__tag">Bedroom</li>
      <li class="card__tag">Bathroom</li>
    </ul>
  
    <p>Бригадир</p>
    <div class="card__executor">
      <img class="avatar" :src="require(`@/assets/images/avatar.jpg`)" alt="avatar">
      <h3>Соколов И.В.</h3>
    </div>

  </section>
</template>

<script>


export default {
  name: 'ObjectDescription',
  props: ['description', 'count'],
}
</script>

<style lang="sass" scoped>
h2
  margin-bottom: 3rem
h3
  font-size: 1.6rem
  font-weight: 400
  margin-bottom: 2.4rem
p
  font-size: 1.3rem
  margin-bottom: 1rem
  color: #979799
  
.card
  display: flex
  background-color: $white
  flex-direction: column
  justify-content: space-between
  margin-bottom: 2.8rem
  padding: 2.4rem 0 2.4rem 2.4rem
  position: relative
  height: 14rem
  width: 100%
  max-width: 24rem
  &__executor
    display: flex
    align-items: center
    cursor: pointer
    & img
      border-radius: 50%
      margin-right: 1rem
      width: 4rem
    & h3
      font-size: 1.4rem
      font-weight: 500
      line-height: 1.4rem
      margin: 0
  &__tags
    display: flex
    flex-wrap: wrap
    gap: 0.8rem
    margin-bottom: 2.4rem
  &__tag
    background-color: $background
    border-radius: 2rem
    font-size: 1.2rem
    padding: 0.6rem 1.2rem

</style>