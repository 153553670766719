<template>
  <div class="wrapper">
    <router-view name="sidebar"></router-view>
    <div class="main">
      <router-view name="header"></router-view>
      <router-view></router-view>
      <router-view name="profile"></router-view>
    </div>
  </div>
  <!-- <router-view name="footer"></router-view> -->
</template>

<script>
import EventBus from './common/EventBus'

export default {
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
  },
  mounted() {
    EventBus.on('logout', () => {
      this.logout()
    })
  },
  beforeUnmount() {
    EventBus.remove('logout')
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;

}
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
