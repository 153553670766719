<template>
  <button class="btn btn--back" @click="backToGroups"><i class="fa-solid fa-arrow-left-long"></i> Назад</button>


  <div class="card">
    <div class="card__header">
      <h3>{{ group.name }}</h3>
      <button class="btn btn--add btn--gray" @click="openModal"><i class="fa-solid fa-plus"></i>Добавить работу</button>
    </div>

    <p v-if="works.length === 0">Нет работ в группе</p>

    <table class="table" v-if="works.length > 0">
      <thead>
        <tr>
          <th>Наименование</th>
          <th>Цена, р.</th>
          <th>Ед. изм.</th>
          <th>Время добавления</th>
          <th>Автор</th>
        </tr>
      </thead>
      
      <tbody>
        <tr v-for="work in works" :key="work.id">
          <td>{{ work.name }}</td>
          <td v-if="work.info[0]">{{ work.info[0].cost }}</td>
          <td v-if="work.info[0]">{{ work.info[0].measurementValue }}</td>
          <td v-if="work.info[0]">{{ work.info[0].createAt }}</td>
          <td v-if="work.info[0]">{{ work.info[0].authorId }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <AddWorkModal 
    v-if="isModalOpen" 
    :id="this.group.id" 
    @close-modal="closeModal"
  />
</template>

<script>
import AddWorkModal from '@/components/Admin/AddWorkModal.vue'

export default {
  name: 'WorksTable',
  props: ['works', 'group'],
  emits: ['back-to-groups'],
  components: {
    AddWorkModal
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  methods: {
    backToGroups() {
      this.$emit('back-to-groups')
    },
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    }
  },
}
</script>

<style lang="sass" scoped>
.card__header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 1.2rem
  & h3
    margin: 0
  & 
.btn
  border: none
  height: auto
  &--back
    padding: 0
    margin-bottom: 3.2rem
  &--add
    font-size: 1.3rem
    background: #ECEEF0
    padding: 0.4rem 0.8rem
    height: 2.8rem
    gap: 0.4rem

</style>