import ObjectService from '../services/object.service'

export default ({
  state: {
    object: {},
    rooms: {
      works: []
    },
    groups: [],
  },
  mutations: {
    SET_OBJECT(state, object) {
      state.object = object
    },
    SET_ROOMS(state, rooms) {
      state.rooms = rooms
    },
    SET_GROUPS(state, groups) {
      state.groups = groups
    },
    REMOVE_OBJECTS(state) {
      state.object = null
      state.rooms = null
      state.groups = null
    },
  },
  actions: {
    async GET_OBJECT({ commit }) {
      try {
        const response = await ObjectService.getObject()
        commit('SET_OBJECT', response.data[0])
        return response
      } catch (error) {
        console.error('Error getting object: ', error)
        return error
      }
    },
    async GET_ROOMS({ commit }, objectId) {
      try {
        const response = await ObjectService.getRooms(objectId)
        commit('SET_ROOMS', response.data)
        return response
      } catch (error) {
        console.error('Error getting rooms: ', error)
        return error
      }
    },
    async GET_ROOM_INFO({ commit }) {
      try {
        const rooms = this.state.object.rooms

        for (let i = 0; i < rooms.length; i++) {
          const response = await ObjectService.getRoomInfo(rooms[i].id)
          rooms[i].info = response.data          
        }
        commit('SET_ROOMS', rooms)
        console.log(rooms);
      } catch (error) {
        console.error('Error getting room info: ', error)
        return error
      }
    },
    async GET_ALL_WORKS({ commit }) {
      try {
        const rooms = this.state.object.rooms
        const workTypes = this.state.object.groups
        const propertyNames = ['name', 'quantity', 'measurementValue', 'cost']
        

        for (let i = 0; i < rooms.length; i++) {
          const response = await ObjectService.getRoomWorks(rooms[i].id)
          const works = response.data

          const sortedWorksByType = {}

          workTypes.forEach(type => {
            const worksOfType = works.filter(work => work.workTypeId === type.id);
            if (worksOfType.length > 0) {
              worksOfType.forEach(w => {
                const descriptionArray = w.workDescription.split(',').map(value => value.trim())
                const descriptionObject = {}
                descriptionArray.forEach((value, index) => {
                  const propertyName = propertyNames[index];
                  descriptionObject[propertyName] = value;
                })
                w.workDescription = descriptionObject
              })
              sortedWorksByType[type.name] = worksOfType
            }
          })
          rooms[i].works = sortedWorksByType
        }
        commit('SET_ROOMS', rooms)        
      } catch (error) {
        console.error('Error getting works in rooms: ', error)
        return error
      }
    },
    async GET_GROUPS({ commit }) {
      try {
        const response = await ObjectService.getGroups()
        commit('SET_GROUPS', response.data)
        return response.data        
      } catch (error) {
        console.error('Error getting work groups: ', error)
        return error
      }
    },
    REMOVE_OBJECT({ commit }) {
      commit('REMOVE_OBJECTS')
    },
  },
  getters: {
    OBJECT(state) {
      return state.object
    },
    ROOMS(state) {
      return state.rooms
    },
    GROUPS(state) {
      return state.groups
    },
  },

})