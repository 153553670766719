<template>
  <div class="search">
    <i class="fa-solid fa-magnifying-glass"></i>
    <input type="text" placeholder="Поиск сообщений">
  </div>
</template>

<style lang="sass" scoped>
.search
  display: flex
  align-items: center
  border-bottom: 1px solid $border
  border-right: 1px solid $border
  grid-area: 2 / 1 / 3 / 2
  padding: 2rem 3.6rem
  height: 7rem
  & i
    font-size: 2.2rem
    opacity: .3
    margin-right: 1.6rem
  & input
    border: none
    padding: 0
    height: 100%
</style>