<template>
  <div class="container" v-if="OBJECT">
    <div class="row">
      <div v-if="loading" class="loading"></div>
      <div v-if="error">ERROR</div>
      <WorksList v-if="object"
        :objectId="object.id"
        :rooms="rooms"
      />
    </div>
    <ObjectDescription :description="OBJECT.description" />
  </div>
  <NoDataScreen v-else>
    <h3>Нет объектов</h3>
  </NoDataScreen>
</template>

<script>
import NoDataScreen from '@/components/Profile/NoDataScreen.vue'
import ObjectDescription from '@/components/Profile/ObjectDescription.vue'
import WorksList from '@/components/Profile/WorksList.vue'
import ObjectService from '../services/object.service'
import axiosInstance from "../services/api"
import TokenService from "../services/token.service"

// import UserService from '../services/user.service'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Profile',
  components: {
    NoDataScreen,
    ObjectDescription,
    WorksList
  },
  data () {
    return {
      user: null,
      username: null,
      object: null,
      rooms: null,

      error: false,
      loading: true,
    }
  },
  computed: {
    ...mapGetters([
      'OBJECT',
      'ROOMS',
    ]),
  },
  methods: {
    ...mapActions([
      'GET_OBJECT',
      'GET_ROOMS',
      'GET_ROOM_INFO',
      'GET_ALL_WORKS',
      'GET_GROUPS',
    ]),

    getObject() {
      Promise.all([
        this.GET_OBJECT()      
          .then(() => this.GET_GROUPS())
          .then(() => this.GET_ROOMS(this.OBJECT.id))
          .then(() => this.GET_ROOM_INFO())
          .then(() => this.GET_ALL_WORKS())
      ]).then(() => {
        this.object = this.OBJECT
        this.rooms = this.ROOMS
        this.loading = false
      }).catch(error => {
        console.error('Error fetching data:', error)
        this.error = true
        this.loading = false
      })
    },
    
    deleteObject() {
      ObjectService.deleteObject(this.object.id)
    },

    async refreshToken() {
      try {
        const response = await axiosInstance.post("/auth/token", {
          refreshToken: TokenService.getLocalRefreshToken(),
        })
        TokenService.updateLocalAccessToken(response.data.accessToken)
      } catch (error) {
        console.log('Refsesh Token Error')
        this.logout()
      }
    }

  },
  created() {
    this.getObject()
  },
  // mounted() {
  //   if (this.$store.state.auth.status.loggedIn === true) {
  //     this.refreshToken()
  //     this.intervalId = setInterval(this.refreshToken, 300000)
  //   }
  // },
  // beforeUnmount() {
  //   clearInterval(this.intervalId)
  // }
}
</script>

<style lang="sass" scoped>

.container
  display: flex
  margin-top: -2.8rem
  & .row
    border-right: 1px solid #E6E6E6
    padding-right: 2.4rem
    padding-top: 2.8rem
    height: calc(100vh - 7rem)
    width: 100%



</style>