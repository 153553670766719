<template>
  <ul class="conversations">
    <li 
      v-for="(conversation, index) in conversations" :key="index"
      :conversation="conversation"
      class="conversation" 
      :class="{'active': index === activeConversationId}"
      @click="chooseConversation(index, conversation)"
    >
      <div class="chat-photo" :class="{'online': conversation.online}">
        <img :src="require(`@/assets/images/avatar.jpg`)" alt="avatar">
      </div>
      <div class="conversation_info">
        <span class="conversation_name">{{ conversation.name }}</span>
        <p class="conversation_message">{{ conversation.message }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Conversations',
  data () {
    return {
      conversations: [
        {
          name: 'Александр Новиков',
          message: 'Lorem ipsum',
          time: '13:54',
          online: true
        },
        {
          name: 'Николай Иванов',
          message: 'Lorem ipsum',
          time: '12:38',
          online: false

        },
        {
          name: 'Марина Соколова',
          message: 'Lorem ipsum',
          time: '10:21',
          online: true
        }
      ],
      activeConversationId: 0
    }
  },
  methods: {
    chooseConversation(index, conversation) {
      this.activeConversationId = index
      this.$emit('chooseConversation', conversation)
    }
  }
}
</script>

<style lang="sass" scoped>
.conversations
  grid-area: 3 / 1 / 5 / 2
  border-right: 1px solid $border
.conversation
  border-bottom: 1px solid $border
  border-left: 3px solid transparent
  display: flex
  align-items: center
  padding: 2rem 3.3rem
  transition: 0.25s
  &:hover
    cursor: pointer
    background: $hover
  &.active
    background: $hover
    border-left: 3px solid $accent
  &_name
    font-weight: 700
    margin: 0
  &_message
    font-size: 1.5rem
    line-height: 2rem
    margin: 0
    opacity: .7


</style>