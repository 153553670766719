<template>
  <aside class="menu" :class="{active: showMenu && windowWidth <= 1100 }">
    <div class="menu_top">
      <router-link to="/" class="menu_logo">
        <i class="fa-solid fa-circle-notch"></i>
        <span>Строй-Сервис</span>
      </router-link>
      <ul>
        <li class="menu_item">
          <router-link to="/profile" class="menu_link">
            <i class="fa-solid fa-house"></i> 
            Главная
          </router-link>
        </li>
        <li class="menu_item">
          <a href="#" class="menu_link">
            <i class="fa-solid fa-trowel-bricks"></i>
            Проекты
          </a>
        </li>
        <li class="menu_item">
          <router-link to="/messages" class="menu_link">
            <i class="fa-solid fa-envelope"></i> 
            Сообщения
          </router-link>
        </li>
        <li class="menu_item">
          <a href="#" class="menu_link">
            <i class="fa-solid fa-users"></i>
            Специалисты
          </a>
        </li>
        <li class="menu_item">
          <a href="#" class="menu_link">
            <i class="fa-solid fa-file-invoice"></i>
            Документы
          </a>
        </li>
      </ul>
    </div>
    <div class="menu_bottom">
      <ul>
        <li class="menu_item">
        <router-link to="/account" class="menu_link">
          <i class="fa-solid fa-gear"></i>
          Настройки
        </router-link>
        </li>
        <li class="menu_item">
          <a href="#" class="menu_link">
            <i class="fa-solid fa-circle-info"></i>
            Поддержка
          </a>
        </li>
        <li class="menu_item">
          <a href="#" class="menu_link" @click.prevent="logout">
            <i class="fa-solid fa-right-from-bracket"></i>
            Выйти
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'SideMenu',
  props: ['showMenu'],
  created() {
    window.addEventListener("resize", this.handleResize)
  },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/')
    }
  },
}
</script>

<style lang="sass" scoped>
.menu
  background: $black
  display: flex
  flex-direction: column
  padding: 0 1.4rem
  //position: fixed
  transition: all 0.4s ease
  top: 0
  left: -30rem
  width: 30rem
  height: 100vh
  z-index: 2
  &::before
    background: $black
    content: ''
    position: absolute
    transition: all 0.4s ease
    opacity: 0
    top: 0
    left: 0
    bottom: 0
    width: 0
  @media (min-width: $medium)
    display: flex
    background: $background
    //position: fixed
    left: 0
    width: 30rem
  &.active
    left: 0
    &::before
      opacity: .25
      width: 100vw
      z-index: -1
      @media (min-width: $medium)
        display: none
    
  &_logo
    display: block
    padding: 3rem 0
    & i
      color: $accent
      font-size: 2.4rem
      transform: rotateY(0deg) rotate(45deg)
      margin-right: 1.6rem
    & span
      font-size: 2.2rem
      font-weight: 700
  &_bottom
    flex: 0 0 auto
    display: flex
    align-items: center
    margin-top: auto
    min-height: 21rem
  &_link
    border-radius: 1.2rem
    color: $gray
    font-weight: 500
    transition: all 0.3s ease
    padding: 1.7rem 2rem
    display: block
    width: 100%
    &:hover
      color: $accent
    @media (min-width: $medium)
      font-size: 1.7rem
      &:hover
        color: $black
    & i
      font-size: 1.8rem
      text-align: center
      margin-right: 0.7rem
      width: 2.1rem

.router-link-exact-active 
  color: $white
  & i
    color: $accent
  @media (min-width: $medium)
    background: $black
  &:hover
    color: $accent
</style>