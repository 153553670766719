import { createStore } from "vuex";
// import createPersistedState from "vuex-persistedstate"
import { auth } from "./auth.module";
import object from "./object.module";
import admin from "./admin.module";

const store = createStore({
  namespaced: true,
  modules: {
    object,
    auth,
    admin
  },
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage,
  // })],
});

export default store;