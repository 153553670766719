import api from './api'

class AdminService {
  getGroups() {
    return api.get('/api/admin/work/type/group')
  }
  getGroupWorks(workTypeGroupId) {
    return api.get(`/api/admin/work/type/${workTypeGroupId}`)
  }

  addWorkToGroup(name, workTypeGroupId) {
    return api
    .post('/api/admin/work/type/', {
      name,
      workTypeGroupId
    })
  }

  addInfoToWork(workTypeId, measurementValue, cost) {
    return api
    .post('/api/admin/work/type/info', {
      workTypeId,
      measurementValue,
      cost
    })
  }

  addGroup(name) {
    return api
    .post('/api/admin/work/type/group', { 
      name 
    })
  }
}

export default new AdminService()