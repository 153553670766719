import api from './api';
import TokenService from './token.service';


class AuthService {
  login({ username, password }) {
    return api
      .post('/auth/login', {
        username,
        password
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data)
        }
        return response.data
      })
  }

  role(userId) {
    return api.get(`/api/users/${userId}`)
  }

  logout() {
    TokenService.removeUser()
    // sessionStorage.clear()
  }

  register({ username, password, role }) {
    return api.post('/auth/register', {
      username,
      password,
      role
    })
  }
}

export default new AuthService();
