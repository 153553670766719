<template>
  <div class="wrapper">
    <div class="container">
      <SearchSection />
      <div class="grid">
        <FilterSideBar />
        <ObjectsList />
      </div>
    </div>
  </div>
</template>

<script>

import TokenService from "../services/token.service"
import axiosInstance from "../services/api"

import SearchSection from '@/components/Specialist/SearchSection.vue'
import FilterSideBar from '@/components/Specialist/FilterSideBar.vue'
import ObjectsList from '@/components/Specialist/ObjectsList.vue'

export default {
  name: 'Specialist',
  components: {
    SearchSection,
    FilterSideBar,
    ObjectsList,

  },
  data () {
    return {
      object: null,
    }
  },
  methods: {
    refreshToken() {
      axiosInstance.post("/auth/token", {
        refreshToken: TokenService.getLocalRefreshToken(),
      }).then(
        response => {
          TokenService.updateLocalAccessToken(response.data.accessToken)
        }
      )
    },
  },
  mounted() {
    this.refreshToken()
    this.intervalId = setInterval(this.refreshToken, 300000)
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  background: $white
  display: flex
  margin-top: 8.6rem
  min-height: calc(100vh - 9rem)

.container
  padding: 3.6rem
  
.grid
  display: grid
  grid-template-columns: 27% 1fr
  column-gap: 2rem
</style>