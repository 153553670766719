<template>
  <div class="header">
    <button class="btn btn--accent">
      <i class="fa-solid fa-plus"></i>
      Новое сообщение
    </button>
  </div>
</template>

<style lang="sass" scoped>
.header
  grid-area: 1 / 1 / 2 / 3
  border-bottom: 1px solid $border
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 2.4rem 3.6rem
  & button
    padding: 0 2rem
    & i
      font-size: 1.3rem
</style>