import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,

  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    role({ commit }) {
      return AuthService.role(this.state.auth.user.userId).then(
        response => {
          const role = response.data.roles[0].name
          commit('role', role);
          return Promise.resolve(role);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    token({ commit }, accessToken) {
      commit('token', accessToken);
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
      state.role = null
    },
    role(state, role) {
      state.role = role
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    token(state, accessToken) { // refreshToken
      state.status.loggedIn = true
      state.user = { ...state.user, accessToken: accessToken }
    }
  }
};
