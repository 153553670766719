<template>
  <WorkGroups 
    v-if="!works" 
    :groups="GROUPS_ADMIN" 
    @show-works="showWorks"
    @open-modal="openModal"
  />
  <WorksTable 
    v-if="works" 
    :works="works" 
    :group="group" 
    @back-to-groups="backToGroups"
  />
  <AddGroupModal 
    v-if="isModalOpen" 
    @close-modal="closeModal"
    @update="GET_GROUPS_ADMIN"
  />
</template>

<script>
import AdminService from "@/services/admin.service"
import WorkGroups from '@/components/Admin/WorkGroups.vue'
import WorksTable from '@/components/Admin/WorksTable.vue'
import AddGroupModal from '@/components/Admin/AddGroupModal.vue'

import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'AdminDashboard',
  components: {
    WorkGroups,
    WorksTable,
    AddGroupModal,
  },
  data () {
    return {
      works: null,
      group: null,
      isModalOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'GROUPS_ADMIN',
    ]),
  },
  methods: {
    ...mapActions([
      'GET_GROUPS_ADMIN',
      'GET_GROUP_WORKS',
    ]),
    showWorks(group) {
      this.group = group
      AdminService.getGroupWorks(group.id)
        .then(response => {
          this.works = response.data
        })
        .catch(error => {
          console.log(error + ' Нет работ в группе')
          this.works = []
        })
    },
    backToGroups() {
      this.works = null
      this.groupName = null
    },
    openModal() {
      this.isModalOpen = true
    },
    closeModal() {
      this.isModalOpen = false
    }
  },
  mounted() {
    this.GET_GROUPS_ADMIN()
  }
}
</script>

<style lang="sass" scoped>
.panel
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 2.8rem
  & span
    color: $gray
    font-size: 2.5rem

.grid
  display: grid
  gap: 2.4rem
  @media screen and (min-width: 768px)
    grid-template-columns: repeat(2, 1fr)
  @media screen and (min-width: 1024px)
    grid-template-columns: repeat(3, 1fr)
  
.card
  display: flex
  flex-direction: column
  transition: 0.2s ease-in-out
  min-height: 12rem
  &:hover
    cursor: pointer
    background-color: #F2F5FA
  & h4
    margin-bottom: 1rem
  & p
    opacity: .6

h3
  margin: 0
</style>
