<template>
  <div class="container">
    <h2>Нет данных</h2>
    <img :src="require(`@/assets/images/box.jpg`)" alt="logo" draggable="false">
    <div class="line"></div>
    <router-link to="/create" class="card">
      <i class="fa-solid fa-hammer"></i>
      <div>
        <h4>Создать объект</h4>
        <p>Чтобы начать работу, создайте новый объект.</p>
      </div>
    </router-link>
  </div>
</template>

<style lang="sass" scoped>
.container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding-top: 10rem
  width: 100%
  max-width: 45.6rem
  & img
    opacity: .3
    width: 8rem

.card
  display: flex
  justify-content: space-between
  cursor: pointer
  width: 100%
  & i
    margin-top: 0.4rem
  & h4
    margin-bottom: 0.8rem
  & p
    color: $gray
.line
  background-color: #E8E8E8
  margin: 2.8rem 0
  height: 1px
  width: 100%
</style>