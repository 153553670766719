import api from './api'

class ObjectService {
  getGroups() {
    return api.get('/api/building/work/type/group')
  }
  getWorksByGroup(workTypeId) {
    return api.get(`/api/building/work/type/${workTypeId}`)
  }

  getObject() {
    return api.get('/api/building/object')
  }
  createObject(owner_id, description) {
    return api
    .post('/api/building/object', {
      owner_id,
      description,
    })
  }
  deleteObject(objectId) {
    return api.delete(`/api/building/object/${objectId}`)
  }

  updateObject(objectId, description) {
    return api.put(`/api/building/object/${objectId}`, {
      description: description
    })
  }

  getRooms(objectId) {
    return api.get(`/api/building/rooms/${objectId}`)
  }
  getRoomInfo(roomId) {
    return api.get(`/api/building/rooms/info/${roomId}`)
  }
  addRoomInfo(roomId, info) {
    console.log(info);
    return api.post(`/api/building/rooms/info/${roomId}`, info)
  }
  deleteRooms(roomId) {
    return api.get(`/api/building/rooms/${roomId}`)
  }
  createRoom(objectId, name, size, description) {
    return api.post('/api/building/rooms', {
      objectId: objectId,
      name: name,
      size: size,
      description: description,
    })
  }
  updateRoom(roomId, name, size, description) {
    return api.put('/api/building/rooms', {
      roomId: roomId,
      name: name,
      size: size,
      description: description,
    })
  }

  addWorkToRoom(roomId, workTypeId, workDescription) {
    return api
    .post('/api/building/work', {
      roomId: roomId,
      workTypeId: workTypeId,
      workDescription: workDescription,
    })
  }
  getRoomWorks(roomId) {
    return api.get(`/api/building/work/room/${roomId}`)
  }
}

export default new ObjectService()