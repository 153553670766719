<template>
  <div class="group" v-if="activeRoom">
    <button class="group__btn" v-for="room in rooms" :key="room.id" @click="chooseRoom(room)" :class="{ active: activeRoom.id === room.id }">
      {{ room.name }}
    </button>
  </div>

  <div class="info card" v-if="activeRoom.info.length > 0">
    <h5>Параметры комнаты</h5>
    <div class="info__grid">
      <div class="info__item" v-for="info in activeRoom.info" :key="info">
        <p>{{ info.type }}</p>
        <p>Длина: {{ info.length }}м²</p>
        <p>Ширина: {{ info.width }}м²</p>
        <p>Высота: {{ info.height }}м²</p>
      </div>
    </div>
    <div>
      Итоговая сумма работ: <strong>{{ totalRoomAmount }} ₽</strong>
    </div>
  </div>

  <div class="type" v-for="(works, group) in activeRoom.works" :key="group">
    <h5 v-if="group">{{ group }}</h5>
    <table class="table">
      <thead>
        <tr>
          <th>Наименование</th>
          <th>Количество</th>
          <th>Стоимость</th>
          <th>Сумма</th>
        </tr>
      </thead>
      
      <tbody>
        <tr v-for="work in works" :key="work.id">
          <td>{{ work.workDescription.name }}</td>
          <td>{{ `${work.workDescription.quantity} ${work.workDescription.measurementValue}` }}</td>
          <td>{{ work.workDescription.cost }} ₽</td>
          <td>
            {{ work.workDescription.quantity * work.workDescription.cost }} ₽
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3"><strong>Итого: </strong></td>
          <td>
            <div class="table__total">
              <strong>{{ totalGroupAmount[group] }} ₽</strong>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>

  </div>



</template>

<script>
// import ObjectService from '@/services/object.service'


export default {
  name: 'WorksList',
  props: ['objectId', 'rooms'],
  data () {
    return {
      activeRoom: this.rooms[0],
    }
  },
  methods: {
    chooseRoom(room) {
      this.activeRoom = room
      // ObjectService.getRoomInfo(this.activeRoom.id).then(response => {
      //   this.activeRoom.info = response.data
      //   console.log(response.data);
      // })
    },
  },
  computed: {
    totalRoomAmount() {
      let total = 0
      for (let group in this.activeRoom.works) {
        this.activeRoom.works[group].forEach(work => {
          total += work.workDescription.quantity * work.workDescription.cost
        })
      }
      return total
    },
    totalGroupAmount() {
      let totalAmounts = {}
      for (let group in this.activeRoom.works) {
        let total = 0
        this.activeRoom.works[group].forEach(work => {
          total += work.workDescription.quantity * work.workDescription.cost
        })
        totalAmounts[group] = total
      }
      return totalAmounts
    }
  }
}
</script>

<style lang="sass" scoped>
h5
  margin: 0 0 0.4rem

.card
  padding: 1.2rem 2.4rem
  margin-bottom: 1.6rem

tr
  border-bottom: 1px solid #ECEEF0
  &:last-of-type
    border-bottom: none

tfoot 
  & tr
    border-bottom: none
  & td
    padding-top: 0.2rem
    padding-bottom: 0
    padding-left: 0
    

strong
  font-weight: 600
  font-size: 1.3rem

.group
  display: flex
  align-items: center
  gap: 1.6rem
  margin-bottom: 2.6rem
  &__btn
    font-size: 1.4rem
    font-weight: 400
    line-height: 2rem
    opacity: 0.4
    position: relative
    &.active
      opacity: 1
      &::before
        background: black
        position: absolute
        content: ''
        bottom: -2px
        height: 2px
        width: 100%
  & .btn
    border-radius: 0.8rem
    font-size: 1.4rem
    margin-left: auto
    height: 2.8rem

.bar
  background-color: #F7F9FB
  border-radius: 0.8rem
  display: flex
  align-items: center
  justify-content: space-between
  gap: 1.4rem
  padding: 0.8rem 2.4rem
  margin-bottom: 1.2rem
  height: 4.4rem
  width: 100%
  & button:last-of-type
    rotate: 90deg

.search
  background-color: #FAFBFD
  border-radius: 0.8rem
  border-color: #E9EAEC
  font-size: 1.4rem
  height: 2.8rem
  max-width: 16rem
  &::placeholder
    color: #CDCECF

.type
  background-color: $background
  border-radius: 1.6rem
  padding: 2.4rem
  margin-bottom: 2.8rem


.table
  & td
    &:first-child
      width: 40%
  &__total
    background-color: #ECEEF0
    border-radius: 0.8rem
    display: flex
    align-items: center
    padding: 0.8rem 1.2rem

.info
  background: none
  border: 1px solid #E6E6E6
  padding: 2.4rem
  & h5
    margin-bottom: 1.4rem
  &__grid
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 2rem
    margin-bottom: 2rem

</style>