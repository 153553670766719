<template>
  <ul class="list">
    <li 
      v-for="object in objects"
      :key="object.id"
      class="item"
      @click="viewObject"
    >
      <div class="item__header">
        <h4>{{ object.description }}</h4>
      </div>
      <div class="item__works">
        <p>
          <span v-for="(work, id) in object.works" :key="id">
            {{ `${id < object.works.length - 1 ? work + ' · ' : work}` }}
          </span>
        </p>
      </div>
      <div class="item__info">
        <p>
          <i class="fa-regular fa-calendar"></i>
          {{ object.createdAt }}
        </p>
        <p>
          <i class="fa-regular fa-user"></i>
          {{ object.user }}
        </p>
      </div>
      <span class="item__date">{{ object.createdAt }}</span>
      <button class="btn">Подробнее</button>
    </li>
    <li 
    v-for="object in objects"
    :key="object.id"
    class="item"
    @click="viewObject"
  >
    <div class="item__header">
      <h4>{{ object.description }}</h4>
    </div>
    <div class="item__works">
      <p>
        <span v-for="(work, id) in object.works" :key="id">
          {{ `${id < object.works.length - 1 ? work + ' · ' : work}` }}
        </span>
      </p>
    </div>
    <div class="item__info">
      <p>
        <i class="fa-regular fa-calendar"></i>
        {{ object.createdAt }}
      </p>
      <p>
        <i class="fa-regular fa-user"></i>
        {{ object.user }}
      </p>
    </div>
    <span class="item__date">{{ object.createdAt }}</span>
    <button class="btn">Подробнее</button>
  </li>
  </ul>
</template>

<script>
export default {
  name: 'ObjectsList',
  data () {
    return {
      objects: [
        {
          description: 'пр. Карла Маркса, д. 168',
          works: ['Демонтажные работы', 'Монтажные работы', 'Отделочные работы'],
          user: 'Марина',
          createdAt: '01 декабря в 18:30'
        },
        {
          description: 'пр. Сиреневый, д. 18',
          works: ['Монтажные работы', 'Строительные работы'],
          user: 'Николай',
          createdAt: '01 декабря в 21:24'
        },
        {
          description: 'ул. Ворошилова, д. 25',
          works: ['Отделочные работы'],
          user: 'Алексей',
          createdAt: '30 ноября в 10:54'
        },
      ]
    }
  },
  methods: {
    viewObject() {
      this.$router.push('./spec/object')
    }
  },
}
</script>

<style lang="sass" scoped>
.list
  grid-column: 2 / 3
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 1.6rem
.item
  border: 1px solid $border
  border-radius: $border-radius
  display: flex
  flex-direction: column
  cursor: pointer
  padding: 2.4rem 2rem
  position: relative
  transition: 0.25s
  min-height: 20rem
  &:hover
    border-color: $accent
    & > button i
      opacity: 1
      color: $accent
  &__header
    display: flex
    & h4
      margin-bottom: 0.6rem
  &__works
    margin-bottom: 1rem
    flex-wrap: nowrap
  & p
    font-weight: 400
    line-height: 2rem
    margin: 0
  &__info
    margin-top: auto
    margin-bottom: 1rem
    & p, i
      font-size: 1.4rem
  &__date
    color: $gray
    position: absolute
    font-size: 1.4rem
    right: 2rem
    bottom: 7.9rem
  & .btn
    background: $accent-light
    border: none
    color: $accent
    width: 100%

</style>