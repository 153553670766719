<template>
  <div class="wrapper">
    <div class="container">
      <h3>Информация об объекте</h3>
      <div class="card">
        <div class="card__header">
          <div class="card__user">
            <img :src="require(`@/assets/images/user-photo.jpg`)" alt="avatar">
            <div>
              <p>{{ object.user }}</p>
              <p class="light">Заказчик</p>
            </div>
          </div>
          <div class="card__status"><i class="fa-regular fa-hourglass"></i>Поиск специалиста</div>
          
          
        </div>
        <ul class="card__info">
          <li>
            <p class="light">Адрес</p>
            <p>
              пр. Карла Маркса, д. 168
            </p>
          </li>
          <li>
            <p class="light">Тип помещения</p>
            <p>
              Квартира
            </p>
          </li>
          <li>
            <p class="light">Начало</p>
            <p>
              01 дек. 2023
            </p>
          </li>
          <li>
            <p class="light">Окончание</p>
            <p>
              01 мар. 2023
            </p>
          </li>
        </ul>

      <table class="table">
        <thead>
          <tr>
            <th v-for="(tableHeader, id) in tableHeaders" :key="id">
              {{ tableHeader }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="work in object.works" :key="work.id">
            <td>{{ work }}</td>
            <td>100</td>
            <td>30000</td>
            <td><input type="checkbox" name="" id=""></td>
          </tr>
        
        </tbody>
      </table>
      <button class="btn btn--accent">Принять в работу</button>
    </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'ObjectsList',
  data () {
    return {
      object: {
        description: 'пр. Карла Маркса, д. 168',
        works: ['Демонтажные работы', 'Монтажные работы', 'Отделочные работы'],
        user: 'Марина',
        createdAt: '01 декабря в 18:30'
      },
      tableHeaders: [
        'Наименование',
        'Кол-во',
        'Сумма',
        ''
      ],
    }
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  background: $white
  display: flex
  margin-top: 8.6rem
  min-height: calc(100vh - 9rem)
  @media (min-width: $medium)
    margin-left: 30rem
.container
  padding: 3.6rem
  max-width: 100rem

h3
  margin-bottom: 2rem
.card
  border: 1px solid $border
  padding: 4rem 3rem 2.8rem
  &__header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 2.4rem
  &__status
    background-color: $accent-light
    border-radius: 5rem
    font-size: 1.4rem
    cursor: default
    padding: 1rem 2rem
    & i
      font-size: 1.3rem
      margin-right: 1rem
  &__user
    cursor: pointer
    display: flex
    align-items: center
    gap: 1.4rem
    & p
      line-height: normal
      &:first-of-type
        font-size: 1.8rem
        font-weight: 600
        margin-bottom: 0.2rem
    & img
      border-radius: 50%
      object-fit: cover
      object-position: center
      height: 5rem
      width: 5rem
  &__info
    border-top: 1px solid $border
    border-bottom: 1px solid $border
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 1.5rem
    padding: 2.2rem 0 1.6rem
    margin-bottom: 2.4rem
  & button
    margin-left: auto
    margin-top: 2rem
p.light
  font-size: 1.4rem
  color: $gray
  line-height: 1.4rem
</style>