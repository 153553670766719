<template>
  <div class="home">
    <HeroSection @open-modal="showModal = true"/>
  </div>
</template>

<script>
import HeroSection from '@/components/Home/HeroSection.vue'

export default {
  name: 'Home',
  components: {
    HeroSection,
  }
}
</script>
