<template>
  <div class="container">
      <ObjectType 
        v-if="component === 'ObjectType'" 
        @create-object="createObject"
      />
      <ObjectRooms 
        v-if="component === 'ObjectRooms'"
        :rooms="rooms" 
        @choose-room="chooseRoom"
        @create-room="createRoom"
        @set-component="setComponent"
      />
      <ObjectParameters 
        v-if="component === 'ObjectParameters'"
        :choosedRoom="room"
        @set-component="setComponent"
        @update-rooms="updateRooms"
      />
      <ObjectAddress 
        v-if="component === 'ObjectAddress'"
        @set-component="setComponent"
        @create-object="createObject"
      />
  </div>
</template>

<script>
import ObjectType from '@/components/Create/ObjectType.vue'
import ObjectRooms from '@/components/Create/ObjectRooms.vue'
import ObjectParameters from '@/components/Create/ObjectParameters.vue'
import ObjectAddress from '@/components/Create/ObjectAddress.vue'
import ObjectService from '../services/object.service'
import UserService from '../services/user.service'

export default {
  name: 'Create',
  components: {
    ObjectType,
    ObjectRooms,
    ObjectParameters,
    ObjectAddress
  },
  data () {
    return {
      component: 'ObjectType',
      ownerId: null,
      object: null,
      rooms: [],
      room: null
    }
  },
  methods: {
    setComponent(component) {
      this.component = component
    },
    chooseRoom(room) {
      this.room = room
      this.component = 'ObjectParameters'
    },
    updateObject(value) {
      this.object.description = value
    },
    updateWorks(works) {
      const obj = this.object.works.find(n => (n.id === works.id))
      if (obj) {
        Object.assign(obj, works);
      } else {
        this.object.works.push(works);
      }
      console.log(this.object.works);
    },
    updateRooms(name, info) {
      ObjectService.updateRoom(this.room.id, name, 0, this.room.description).then(response => {
          this.room = response.data
          if (response.data) {
          ObjectService.addRoomInfo(this.room.id, info).then(response => {
            console.log(response.data);
          })
        }
          console.log('OK');
        })
      this.component = 'ObjectRooms'

    },
    createRoom() {
      ObjectService.createRoom(this.object.id, `Комната ${this.rooms.length + 1}` , 0, 'Комната').then(response => {
        if (response.data) {
          ObjectService.getRooms(this.object.id).then(response => {
            this.rooms = response.data
          })
        }
      })
    },

    async createObject(description) {
      if (!this.object) {
        try {
          const createObjectResponse = await ObjectService.createObject(this.ownerId, description)
          this.object = createObjectResponse.data
          
          await ObjectService.createRoom(this.object.id, `Комната ${this.rooms.length + 1}` , 0, 'Комната')

          const createRoomResponse = await ObjectService.getRooms(this.object.id)
          this.rooms = createRoomResponse.data

          this.setComponent('ObjectRooms')

        } catch (error) {
          console.error('Error creating object: ', error)
          return error
        }
      } else {
        ObjectService.updateObject(this.object.id, description).then(response => {
          this.object = response.data
        })
        this.setComponent('ObjectRooms')
      }
    },

  },
  mounted() {
    UserService.getUser().then(response => { 
      this.ownerId = response.data.id
    })
    ObjectService.getObject().then(response => {
      if (response.data[0]) {
        this.object = response.data[0]
        ObjectService.getRooms(this.object.id).then(response => {
          if (response.data) {
            this.rooms = response.data
          }
        })
      }
    })
  },
}
</script>


<style lang="sass" scoped>
.container
  background-color: $background
  padding-top: 10rem
  padding-bottom: 5rem
  display: flex
  align-items: center
  justify-content: center
  min-height: 100vh
  max-width: none
  width: 100%
.card
  background-color: $white
  border: none
  margin: 0 auto
  width: 100%
  max-width: 86rem
</style>
