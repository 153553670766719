<template>
  <div class="login">
    <h2 class="login__title">Войти</h2>
    <p>Через социальные сети</p>
    <ul class="login__social">
      <li>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <path fill="#1976d2" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M35.937,18.041c0.046-0.151,0.068-0.291,0.062-0.416C35.984,17.263,35.735,17,35.149,17h-2.618 c-0.661,0-0.966,0.4-1.144,0.801c0,0-1.632,3.359-3.513,5.574c-0.61,0.641-0.92,0.625-1.25,0.625C26.447,24,26,23.786,26,23.199 v-5.185C26,17.32,25.827,17,25.268,17h-4.649C20.212,17,20,17.32,20,17.641c0,0.667,0.898,0.827,1,2.696v3.623 C21,24.84,20.847,25,20.517,25c-0.89,0-2.642-3-3.815-6.932C16.448,17.294,16.194,17,15.533,17h-2.643 C12.127,17,12,17.374,12,17.774c0,0.721,0.6,4.619,3.875,9.101C18.25,30.125,21.379,32,24.149,32c1.678,0,1.85-0.427,1.85-1.094 v-2.972C26,27.133,26.183,27,26.717,27c0.381,0,1.158,0.25,2.658,2c1.73,2.018,2.044,3,3.036,3h2.618 c0.608,0,0.957-0.255,0.971-0.75c0.003-0.126-0.015-0.267-0.056-0.424c-0.194-0.576-1.084-1.984-2.194-3.326 c-0.615-0.743-1.222-1.479-1.501-1.879C32.062,25.36,31.991,25.176,32,25c0.009-0.185,0.105-0.361,0.249-0.607 C32.223,24.393,35.607,19.642,35.937,18.041z"></path>
        </svg>
        Войти через VK
      </li>
      <li>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
        </svg>
        Войти через Google
      </li>
    </ul>
    <div class="divide">
      <div class="divide__line"></div>
        <p>Или через почту</p>
      <div class="divide__line"></div>
    </div>
    <Form class="login__form" @submit="handleLogin" :validation-schema="schema">
      <div class="login__form-group">
        <Field type="text" name="username" placeholder="Имя пользователя / Email" />
        <ErrorMessage name="username" class="error-feedback" />
      </div>
      <div class="login__form-group">
        <Field type="password" name="password" placeholder="Пароль" />
        <ErrorMessage name="password" class="error-feedback" />
      </div>
      <div class="login__form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
      <button class="btn btn--black">Войти</button>
    </Form>
    <div class="login__bar">
      <a href="#" @click="this.$emit('register')">Регистрация</a>
      <a href="#">Забыли пароль?</a>
    </div>
    

  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate"
import * as yup from "yup"

export default {
  name: 'LoginForm',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data () {
    const schema = yup.object().shape({
      username: yup.string().required('Введите имя пользователя или email'),
      password: yup.string().required('Введите пароль'),
    })

    return {
      schema,
      message: ''
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/profile')
    // }
  },
  methods: {
    handleLogin(user) {
      this.$store.dispatch('auth/login', user).then(
        () => {
          this.$store.dispatch('auth/role').then(
            () => {
              const role = this.$store.state.auth.role
              if (role === 'ROLE_ADMIN') {
                this.$router.push('/admin')
              } else if (role === 'ROLE_SPECIALIST') {
                this.$router.push('/spec')
              } else {
                this.$router.push('/profile')
              }
            }
          ),
          (error) => {
            console.log(error)
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
  }
}
</script>

<style lang="sass" scoped>
.login
  text-align: center
  margin: 0 auto
  width: 100%
  max-width: 38.4rem
  &__title
    text-align: center
    margin-bottom: 0.8rem
  & p
    opacity: .4
    margin-bottom: 2.8rem
  &__social
    display: flex
    align-items: center
    justify-content: center
    gap: 1.6rem
    & li
      border-radius: 1.2rem
      border: 1px solid #E8E8E8
      font-size: 1.3rem
      cursor: pointer
      display: flex
      align-items: center
      transition: .2s ease-in-out
      padding: 0 0 0 1.2rem
      height: 4.4rem
      width: 100%
      &:hover
        border-color: $gray
      & svg
        margin-right: 0.8rem
        height: 2.4rem
        width: 2.4rem
  &__bar
    display: flex
    justify-content: center
    align-items: center
    font-size: 1.4rem
    gap: 1.6rem
    & a
      color: #5079cc
      font-weight: 400
  &__form-group
    margin-bottom: 1.6rem
    position: relative
  & input
    border-radius: 1.2rem
    padding: 1rem 1.2rem
    height: 4.4rem
    width: 100%

.divide
  display: flex
  align-items: center
  margin: 2.8rem 0
  &__line
    background: #E8E8E8
    height: 1px
    width: 100%
  & p
    margin: 0 1.6rem
    width: 100%
.btn
  border-radius: 1.2rem
  margin-bottom: 2.8rem
  height: 4.4rem
  width: 100%

</style>
