<template>
  <div class="chat">
    <div class="response">
      <div class="message message--response">
        <div class="chat-photo" :class="{'online': conversation.online}">
          <img :src="require(`@/assets/images/avatar.jpg`)" alt="avatar">
        </div>
        <div class="message_text">
          <p>
            Necessitatibus qui tempore
          </p>
        </div>
      </div>
  
      <div class="message message--response">
        <div class="message_text message_text--only">
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta, ullam blanditiis facere autem expedita id est cupiditate perspiciatis corporis maiores ratione distinctio quam, magnam enim in nulla, necessitatibus qui tempore.
          </p>
        </div>
      </div>
  
      <div class="message message--response">
        <div class="message_text message_text--only">
          <p>
            Ipsa cupiditate adipisci id ex repellendus expedita dolorum perspiciatis nulla quasi neque, optio temporibus est dolorem illum, magnam voluptatum doloribus omnis excepturi.
          </p>
        </div>
      </div>
      <p class="message_time">13:50</p>
    </div>

    <div class="sent">
      <div 
        v-for="message in messages" 
        :key="message"
        class="message message--sent"
      >
        <p class="message_text">
          {{ message }}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Chat',
  props: ['conversation', 'reply'],
  data () {
    return {
      messages: ['Soluta ipsam amet dignissimos porro']
    }
  },

  onCreated() {
    this.messages.push(this.reply)
  }
}
</script>

<style lang="sass" scoped>
.chat
  grid-area: 3 / 2 / 4 / 3
  padding: 2rem 3.6rem
  overflow-y: scroll
  max-height: calc(100vh - 34.8rem)
.message
  display: flex
  margin-bottom: 0.4rem
  &--response
    &:first-of-type .message_text
      border-bottom-left-radius: 0.4rem
    &:not(:first-of-type):not(:last-of-type) .message_text
      border-radius: 0.4rem 3rem 3rem 0.4rem
    &:not(:first-of-type):last-of-type .message_text
      border-top-left-radius: 0.4rem
      border-bottom-left-radius: 3rem
  &--sent
    & .message_text
      background: $message-sent
      border-radius: 3rem
      margin-left: auto
    &:first-of-type .message_text
      border-bottom-right-radius: 0.4rem
      border-top-right-radius: 3rem
    &:not(:first-of-type):not(:last-of-type) .message_text
      border-radius: 3rem 0.4rem 0.4rem 3rem
    &:not(:first-of-type):last-of-type .message_text
      border-top-right-radius: 0.4rem

  &_text
    background: $message
    border-radius: 3rem
    padding: 1.4rem 2.4rem
    line-height: 2.6rem
    max-width: 60rem
    &--only
      margin-left: 6.4rem

  &_time
    font-size: 1.2rem
    opacity: .5
    margin-left: 6.4rem
    margin-bottom: 1.4rem

.sent
  & .message_time
    margin-left: auto
    float: right


    
.chat-photo
  margin-right: 2rem
  &:hover
    cursor: pointer
</style>