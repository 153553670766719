<template>
  <header class="header">
    <div class="header__container">
      <div class="header__left">
        <router-link to="/" class="header__logo">
          <img class="logo" :src="require(`@/assets/images/logo.png`)" alt="logo" draggable="false">
          <p><b>Строй</b>Сервис</p>
        </router-link>
        <router-link to="/admin" v-if="role === 'ROLE_ADMIN'">
          Список работ
        </router-link>

      </div>
      <div class="header__right">
        <button><i class="fa-solid fa-bell"></i></button>
        <button><i class="fa-solid fa-gear"></i></button>
        <div class="header__user" @click="showDropdown = !showDropdown">
          {{ username }}
          <img class="avatar" :src="require(`@/assets/images/user-photo.jpg`)" alt="avatar">
        </div>
        <DropdownMenu v-if="showDropdown" :class="{active: showDropdown}" @close="showDropdown = false"/>

      </div>
    </div>
    <!-- <div class="header_wrapper">
      <div class="header_bell">
        <button><i class="fa-regular fa-bell"></i></button>
      </div>
      <div class="header_user" @click="showDropdown = !showDropdown">
        <img class="avatar" :src="require(`@/assets/images/user-photo.jpg`)" alt="avatar">
        {{ username }}
        <i class="fa-solid fa-chevron-down"></i>
      </div>
      <DropdownMenu v-if="showDropdown" :class="{active: showDropdown}" @close="showDropdown = false"/>
    </div> -->
  </header>
</template>

<script>
import DropdownMenu from '@/components/Layout/DropdownMenu.vue'
import UserService from '@/services/user.service'
import EventBus from '@/common/EventBus'

export default {
  name: 'AppHeader',
  components: {
    DropdownMenu
  },
  data () {
    return {
      showDropdown: false,
      user: null,
      username: null,
      role: null,
    }
  },
  mounted() {
    UserService.getUser().then(
      response => { 
        this.user = response.data
        this.username = this.user.username
        this.role = this.user.roles[0].name
      },
      error => {
        if (error.response && error.response.status === 403) {
          EventBus.dispatch('logout')
        }
      }
    )
  }
}
</script>

<style lang="sass" scoped>
.header
  border-top: 0.8rem solid $accent
  border-bottom: 1px solid #E8E8E8
  background-color: $white
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 2.8rem
  height: 6.8rem
  z-index: 1
  width: 100%
  &__container,
  &__left,
  &__right,
  &__links
    display: flex
    align-items: center
    gap: 3rem
  &__container
    justify-content: space-between
    padding: 0 2.8rem
    margin: 0 auto
    width: 100%
    max-width: 122.8rem
  &__logo
    cursor: pointer
    display: flex
    align-items: center
    font-size: 1.6rem
    & p, b
      font-size: 1.6rem
    & img
      margin-right: 1rem
      width: 2.6rem
  &__links a
    font-size: 1.4rem
  &__right
    gap: 2rem
    position: relative
  &__user
    cursor: pointer
    display: flex
    align-items: center
    gap: 1rem
  & .toggle
    display: block
    @media (min-width: $medium)
      display: none
  & .avatar
    border-radius: 50%
    object-fit: cover
    object-position: center
    height: 3rem
    width: 3rem

</style>
