<template>
  <div class="card_content">
    <div class="card_header">
      <h3>Адрес</h3>
      <button class="close" @click="this.$parent.$emit('close')">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <form class="form">
      <div class="form-group">
        <label for="address">Введите адрес объекта</label>
        <input name="address" type="text" class="address__form-control" 
        :value="modelValue" 
        @input="$emit('update:modelValue', $event.target.value)"/>
      </div>
    </form>

    <div class="card_footer">
      <button class="btn btn--white" @click="this.$emit('set-component', 'ObjectRooms')">
        Назад
      </button>
      <button class="btn btn--black" @click="create">
        Сохранить
      </button>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ObjectAddress',
  emits: ['set-component'],
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  methods: {
    create() {
      this.$router.push('/profile')
    }
  }
}
</script>

<style lang="sass" scoped>
.card
  &_footer
    display: flex
    justify-content: space-between
.form
  margin-bottom: 3rem
</style>