<template>
  <header class="header">
    <div class="header_wrapper container">
      <router-link to="/" class="header_logo">
        <img :src="require(`@/assets/images/logo.png`)" alt="logo" draggable="false">
        <p><b>Строй</b>Сервис</p>
      </router-link>
      <nav class="header_links">
        <router-link to="/register" class="register btn btn--gray" v-if="!loggedIn">Регистрация</router-link>
        <router-link to="/login" class="login btn btn--black">
          {{loggedIn ? 'Профиль' : 'Войти' }}
        </router-link>
      </nav>
    </div>
  </header>
</template>

<script>

export default {
  name: 'HomeHeader',
  computed: {
    loggedIn() {
      if (this.$store.state.auth.status.loggedIn === true) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.header
  position: fixed
  height: 6.8rem
  width: 100%
  z-index: 2
  @media (min-width: $medium)
  &_logo
    width: 36rem
  &_wrapper
    display: flex
    align-items: center
    justify-content: space-between
    height: 100%
  &_logo
    display: flex
    align-items: center
    cursor: pointer
    & p, b
      font-size: 1.8rem
    & img
      margin-right: 1rem
      width: 2.6rem

  &_links
    display: flex
    align-items: center
    gap: 0.8rem
    & *
      font-size: 1.4rem
    & .btn
      border-radius: 0.8rem
      padding: 0 0.8rem
      height: 2.8rem
    & .register
      background: #ECEEF0
      &:hover
        background: #D2D2D2
        

</style>
