<template>
  <div class="container">
    <h3>Доступ запрещен</h3>
    <h2>403</h2>
    <router-link to="/">На главную</router-link>
  </div>
</template>

<style lang="sass" scoped>
.container
  text-align: center
  padding-top: 25rem
  & a
    font-size: 1.8rem
    &:hover
      color: $accent
</style>