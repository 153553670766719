<template>
  <div class="panel">
    <h3>Список работ</h3>
  </div>
  <div class="grid">
    <div class="card" v-for="(group, id) in groups" :key="id" @click="showWorks(group)">
      <h4>{{ group.name }}</h4>
      <p>{{ group.id }}</p>
    </div>
    <div class="card" @click="openModal"><i class="fa-solid fa-plus"></i> Добавить</div>
  </div>
</template>

<script>
export default {
  name: 'WorkGroups',
  props: ['groups'],
  emits: ['show-works', 'open-modal'],
  methods: {
    showWorks(group) {
      this.$emit('show-works', group)
    },
    openModal() {
      this.$emit('open-modal')
    }
  },
}
</script>

<style lang="sass" scoped>
.panel
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 2.8rem
  & span
    color: $gray
    font-size: 2.5rem

.grid
  display: grid
  gap: 2.4rem
  @media screen and (min-width: 768px)
    grid-template-columns: repeat(2, 1fr)
  @media screen and (min-width: 1024px)
    grid-template-columns: repeat(3, 1fr)
  
.card
  display: flex
  flex-direction: column
  transition: 0.2s ease-in-out
  min-height: 12rem
  &:hover
    cursor: pointer
    background-color: #F2F5FA
  & h4
    margin-bottom: 1rem
  & p
    opacity: .6
  &:last-of-type
    background: #FFFFFF
    border: 1px dashed #D2D2D2
    align-items: center
    flex-direction: row
    font-size: 1.6rem
    & i
      margin-right: 0.8rem
    &:hover
      border-color: #000000


h3
  margin: 0
</style>