<template>
  <div>
    <div class="overlay"></div>
    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Количество</th>
            <th>Ед. изм.</th>
            <th>Стоимость, руб.</th>
            <th>Сумма, руб.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="work in works" :key="work">
            <td>{{ work.name }}</td>
            <td><input type="number" placeholder="0" v-model="work.quantity"></td>
            <td v-if="work.info[0]">{{ work.info[0].measurementValue }}</td>
            <td v-if="work.info[0]">{{ work.info[0].cost }}</td>
            <td v-if="work.info[0]">{{ sum(work.quantity, work.info[0].cost) }} р.</td>
          </tr>
        </tbody>
      </table>

      <br><br>
      <div class="works__buttons">
        <button @click.prevent="this.$emit('go-back')" class="btn btn--white">Отмена</button>
        <button @click.prevent="updateObjectWorks" class="btn btn--black">Сохранить</button>
      </div>
    </div>
  </div>

</template>

<script>
import ObjectService from "@/services/object.service"

export default {
  name: 'ObjectWorks',
  props: ['roomId', 'groupId'],
  emits: ['go-back', 'add-data', 'update-works'],
  data () {
    return {
      choosedWorks: [],
      groups: null,
      works: null
    }
  },
  methods: {
    updateObjectWorks() {
      this.choosedWorks = this.works.map(work => ({...work})).filter(work => work.quantity > 0)
      this.choosedWorks.forEach(work => {
        this.addWorkToRoom(
          this.roomId, 
          this.groupId,
          `${work.name}, ${work.quantity}, ${work.info[0].measurementValue}, ${work.info[0].cost}`
        )
      })
      this.$emit('go-back')
    },
    async addWorkToRoom(roomId, groupId, description) {
      try {
        const response = await ObjectService.addWorkToRoom(roomId, groupId, description)
        console.log('Succsesfully added works to room: ', response.data);
      } catch (error) {
        console.log('Error adding works to room: ', error);
      }
    },

    sum(quantity, cost) {
      return quantity ? (quantity * cost) : 0
    },
  },
  mounted() {
    ObjectService.getWorksByGroup(this.groupId).then(response => {
      this.works = response.data
    })
  }

}
</script>

<style lang="sass" scoped>
.overlay
  background-color: $col-black
  opacity: .8
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 100%
  height: 100vh
  z-index: 3
.container
  background-color: $col-white
  border-radius: 0.5rem
  padding: 4rem 3rem
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 86rem
  z-index: 3

.works
  &__header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 3rem
    & h3
      margin: 0
  &__buttons
    display: flex
    align-items: center
    justify-content: space-between
    gap: 1.6rem

.table
  // display: block
  & input[type="number"]
    font-size: 1.6rem
    border: none
    border-radius: 0.6rem
    background-color: #f4f4f4
    padding: 0 1rem
    text-align: right
    height: 3.4rem
    width: 6rem
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
    
.btns
  margin-left: auto
</style>