<template>
  <div class="container">
    <AdminDashboard />
  </div>
</template>

<script>

import TokenService from "../services/token.service"
import axiosInstance from "../services/api"

import AdminDashboard from '@/components/Admin/AdminDashboard.vue'


export default {
  name: 'Admin',
  components: {
    AdminDashboard
  },
  methods: {
    refreshToken() {
      axiosInstance.post("/auth/token", {
        refreshToken: TokenService.getLocalRefreshToken(),
      }).then(
        response => {
          TokenService.updateLocalAccessToken(response.data.accessToken)
        }
      )
    },
  },
  mounted() {
    this.refreshToken()
    this.intervalId = setInterval(this.refreshToken, 300000)
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
  },
}
</script>

<style lang="sass" scoped>

.container

  
</style>