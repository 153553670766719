<template>
  <div class="header">
    <div class="header_left">
      <i class="fa-solid fa-at"></i>
      <span>{{ name }}</span>
    </div>
    <div class="header_right">
      <button><i class="fa-solid fa-table-list"></i></button>
      <button><i class="fa-solid fa-ellipsis-vertical"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name'],
}
</script>

<style lang="sass" scoped>
.header
  grid-area: 2 / 2 / 3 / 3
  display: flex
  align-items: center
  justify-content: space-between
  border-bottom: 1px solid $border
  padding: 2rem 3.6rem 2rem 2rem
  height: 7rem
  &_left
    & i
      font-size: 2.2rem
      opacity: .3
      margin-right: 1.6rem
    & span
      font-size: 2rem
      font-weight: 700
  &_right
    opacity: .5
    & button
      &:not(:last-of-type)
        margin-right: 1.2rem
      & i
        font-size: 2rem
        text-align: center
        padding: 0.8rem
</style>