<template>
  <div class="card card--light">
  <h3>Параметры помещений</h3>
  <ul class="rooms" v-if="rooms">
    <li v-for="room in rooms" :key="room" class="room" @click="chooseRoom(room)">
      <h4>{{ room.name }}</h4>
      <div class="room_area">
        <i class="fa-solid fa-vector-square"></i>
        <p>{{ room.size }} м²</p>
      </div>
      <div class="room_buttons">
        <i class="fa-solid fa-pen-to-square"></i>
        <i @click="deleteRoom(room.index)" v-if="room.index !== 0" class="fa-solid fa-trash"></i>
      </div>
    </li>

    <li class="room" @click="createRoom">
      <h4><i class="fa-solid fa-plus"></i> Добавить комнату</h4>
    </li>
  </ul>


  <div class="card_footer">
    <button class="btn btn--white" @click="this.$emit('set-component', 'ObjectType')">
      Назад
    </button>
    <button class="btn btn--black" @click="this.$emit('set-component', 'ObjectAddress')">
      Далее
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: 'ObjectRooms',
  emits: ['create-room', 'choose-room', 'delete-room', 'set-component'],
  props: ['rooms'],
  methods: {
    createRoom() {
      this.$emit('create-room')
    },
    deleteRoom(index) {
      this.$emit('delete-room', index)
    },
    chooseRoom(room) {
      this.$emit('choose-room', room)
    }
  },
}
</script>

<style lang="sass" scoped>
.rooms
  display: grid
  margin-bottom: 3rem
  gap: 2rem
.room
  background: $background
  border-radius: 1.6rem
  cursor: pointer
  display: flex
  flex-direction: column
  transition: all 0.3s ease
  position: relative
  padding: 3rem
  min-height: 14rem
  width: 100%
  &:hover
    border-color: $gray
  &:last-of-type
    background: $white
    border: 1px dashed $gray
    color: $gray
    display: flex
    align-items: center
    justify-content: center
    & h4
      font-weight: 400
      font-size: 1.4rem
      margin: 0
      & i
        font-size: 1rem
  & h4
    font-weight: 500
    margin-bottom: 0.4rem
  &_area
    display: flex
    align-items: center
    & i
      margin-right: 1rem
    & p
      font-size: 1.6rem
      color: $gray
      margin: 0
  &_buttons
    opacity: .4
    display: flex
    align-items: center
    gap: 1.4rem
    position: absolute
    right: 2rem
    top: 2rem
    z-index: 5
    


</style>